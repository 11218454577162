<template>
  <div>
    <div v-show="!bLoadingTable" class="content-checkbox-all">
    <!-- <checkbox-component-global /> -->
      <!-- <v-checkbox v-model="checkbox" class="global-checkbox global-checkbox-table"
        color="var(--primary-color-text-yellow)">
        <template slot="label">
          <span class="text-checkbox">Seleccionar todo el listado</span>
        </template>
      </v-checkbox> -->
    </div>
    <div class="content-table-mobile" v-if="!bEmptyTable">
      <div v-show="bLoadingTable" class="content-progress-linear">
        <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
      </div>
      <div v-show="!bLoadingTable" v-for="(itemTable, index) in aTable" :key="index">
        <v-container>
          <div class="content-all-first">
            <div class="content-left-first">
              <!--#region CONTENIDO DE MATERIAS PRIMAS -->
              <div class="content-color-id-raw-material">
                <v-checkbox v-model="itemTable.bRestPrice"
                  @change="getRawMaterialsResetPrice(itemTable.bRestPrice, itemTable.sRawMaterialId)"
                  class="global-checkbox global-checkbox-table" color="var(--primary-color-text-yellow)">
                  <!-- <template v-slot:label>
                <span class="text-checkbox">{{ item.text }}</span>
              </template> -->
                </v-checkbox>
                <div :style="{
                  'background-color': '#' + itemTable.sColorKey + '!important',
                }" class="color-raw-material"></div>
              </div>
              <!--#endregion CONTENIDO DE MATERIAS PRIMAS -->
              {{ itemTable.sCode }}
            </div>
            <div v-show="bAdminCustomer" class="content-right-first">
              <menu-accion-table-component-global @openUpdatePrice="openUpdatePrice" :aMenuAccion="aMenuAccion"
                :itemTable="itemTable" />
            </div>
          </div>
          <div class="content-all">
            <div class="content-left">
              <p>Nombre</p>
            </div>
            <div class="content-right">
              <p class="text-right">{{ itemTable.sName }}</p>
            </div>
          </div>
          <!-- <div class="content-all">
          <div class="content-left"><p>Existencia</p></div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.dInventory }} Kg</p>
          </div>
        </div> -->
          <div class="content-all">
            <div class="content-left">
              <p>Precio personalizado</p>
            </div>
            <div class="content-right">
              <!--#region CONTENIDO DE CUENTAS FINANCIERAS -->
              <!-- <span
              :class="itemTable.dAccountBalance > 0 ? 'class-in' : 'class-out'"
            >
              <span>{{ itemTable.dAccountBalance > 0 ? "+" : "" }}</span>
              {{ formatMoneyGlobal(itemTable.dAccountBalance) }}
              {{ itemTable.sCurrency }}</span
            > -->
              <!--#endregion CONTENIDO DE CUENTAS FINANCIERAS -->
              <p class="text-right">{{ itemTable.dUnitPrice }}</p>
            </div>
          </div>
          <!--#region CONTENIDO O.C. PAYABLE -->
          <!-- <div class="content-all" v-show="showRowsOPPayable()">
          <div class="content-left"><p>Monto acumulado</p></div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sCumulativeAmount }}</p>
          </div>
        </div> -->
          <!--#endregion CONTENIDO O.C. PAYABLE -->
          <div class="content-all">
            <div class="content-left">
              <p>Última modificación de precio</p>
            </div>
            <div class="content-right">
              <p class="text-right">{{ itemTable.tUpdatedAt }}</p>
            </div>
          </div>
        </v-container>
        <v-divider class="divider-global mt-3 mb-3"></v-divider>
      </div>

      <footer-table-component-global :numPages="numPages" :totalItems="totalItems" @changeTable="changeTable"
        :bChangeSelectedItem="bChangeSelectedItem" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    aTable: Array,
    numPages: Number,
    totalItems: Number,
    bChangeSelectedItem: Boolean,
    bAdminCustomer: Boolean

  },
  data() {
    return {
      bLoadingTable: true,
      bEmptyTable: false,

      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      totalView: 0,
      aMenuAccion: [
        // {
        //   text: "Detalle",
        //   icon: "mdi-eye",
        //   toGo: "ProviderDetail",
        //   color: "var(--primary-color-text-menu-detail) !important",
        //   action: 1,
        // },
        // {
        //   text: "Eliminar",
        //   icon: "mdi-trash-can-outline",
        //   toGo: "ProviderDetail",
        //   color: "var(--primary-color-btn-table-delete) !important",
        //   action: 2,
        // },
        {
          text: "Actualizar precio",
          icon: "",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 3,
        },
      ],
      aRowsOPPayable: [],
    };
  },
  beforeMount() {
    this.setLoadingTable();
  },
  methods: {
    //#region FUNCTIONS GLOBALS //
    setLoadingTable() {
      this.bLoadingTable = true;
      this.bEmptyTable = false;
      setTimeout(() => {
        this.bLoadingTable = false;
        this.bEmptyTable = this.aTable.length < 1;
      }, 1000);
    },
    openDetailItem(item) {
      this.$emit("openDetailItem", item);
    },
    openDeleteItem(item) {
      this.$emit("openDeleteItem", item);
    },
    changeTable(obj) {
      this.$emit("changeTable", obj);
    },

    //#endregion   FUNCTIONS GLOBALS //

    //#region FUNCTIONS RAW MATERIALS //
    getColor(difference) {
      if (difference > 0) return "#00A85B";
      else if (difference < 0) return "#E85353";
      else return "#FFD54F";
    },
    getIcon(difference) {
      if (difference > 0) return "mdi-arrow-up";
      else if (difference < 0) return "mdi-arrow-up";
      else return "mdi-arrow-left-right";
    },

    getRawMaterialsResetPrice(bResetPrice, sRawMaterialId) {
      if (bResetPrice) {
        this.$store.commit("setPushRawMateriasResetPrice", sRawMaterialId)
      } else {
        this.$store.commit("setRemoveRawMateriasResetPrice", sRawMaterialId)
      }
    },
    findRawMaterialsResetPrice(sRawMaterialId) {
      return this.aRawMaterialsResetPrice.includes(sRawMaterialId);
    },
    //#endregion FUNCTIONS RAW MATERIALS //

    //#region FUNCTION O.C. POR PAGAR //
    showRowsOPPayable() {
      return this.$route.name === "OPPayable";
    },
    //#endregion FUNCTION O.C. POR PAGAR //

    //#region   FUNCTION DETAIL PROVIDER (RAW MATERIAL) //
    openUpdatePrice(item) {
      this.$emit("openUpdatePrice", item);
    },
    //#endregion   FUNCTION DETAIL PROVIDER (RAW MATERIAL) //
  },
  computed: {
    aRawMaterialsResetPrice() {
      return this.$store.state.aRawMaterialsResetPrice
    },
  },
  watch: {
    aTable() {
      this.setLoadingTable();
    }
  }
};
</script>

<style scoped>
/*#region CSS GLOBAL  */
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.content-checkbox-all{
  margin-bottom: 15px;
}

.text-checkbox {
  font-family: "pop-regular";
  font-size: 17px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

/*#endregion CSS GLOBAL  */

/*#region CSS RAW MATERIAL */

/*#region CSS SKEYCOLOR */
.content-color-id-raw-material {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.color-raw-material {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid var(--primary-color-text);
}

/*#endregion CSS SKEYCOLOR */

/*#region CSS VARIATION */
.content-chip-difference {
  display: flex;
  justify-content: end;
  width: 100%;
}

.chip-difference {
  align-content: center;
  width: 120px;
  text-align: center !important;
  justify-content: center;
  display: flex;
  font-family: "pop-Bold";
}

.content-text-icon-variation {
  display: flex;
  width: 120px;
}

.content-icon-variation {
  width: auto !important;
}

.text-variation {
  width: 100% !important;
  text-align: end !important;
  align-self: center !important;
}

/*#endregion CSS VARIATION */

/*#endregion CSS RAW MATERIAL */

/*#region  CSS ACCOUNTS */
.class-in {
  color: var(--primary-color-text-in) !important;
}

.class-out {
  color: var(--primary-color-text-out) !important;
}

/*#endregion  CSS ACCOUNTS */
</style>